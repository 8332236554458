import request from "@/request";
import { ResultCommon } from "@/types/common";
import { PayMentType, VCodeType } from "@/types/pay";

export const getPrice = (data: any): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-product/list/product/price",
    data,
  });
};

export const payPost = (data: PayMentType) => {
  return request.post({
    url: "/v-product/product/pay",
    data,
  });
};

export const payResult = (id: string) => {
  return request.post({
    url: "/v-product/product/pay/query",
    data: {
      orderNum: id,
    },
  });
};

export const verifyCode = (data: VCodeType) => {
  return request.post({
    url: "/v-product/vcode/verify/code",
    data,
  });
};
